define("discourse/plugins/df-core-private/discourse/raw-templates/topic-list-header", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "	<th class='bulk-select topic-list-data'>\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "canBulkSelect", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 2
          },
          "end": {
            "line": 13,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "	</th>\n";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "			" + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "flat-button", {
        "name": "raw",
        "hash": {
          "title": "topics.bulk.toggle",
          "icon": "list-check",
          "class": "bulk-select"
        },
        "hashTypes": {
          "title": "StringLiteral",
          "icon": "StringLiteral",
          "class": "StringLiteral"
        },
        "hashContexts": {
          "title": depth0,
          "icon": depth0,
          "class": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 12,
            "column": 3
          },
          "end": {
            "line": 12,
            "column": 89
          }
        }
      })) + "\n";
    },
    "4": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "	" + container.escapeExpression((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "screenreaderOnly": "true",
          "order": "posters",
          "name": "df_empty"
        },
        "hashTypes": {
          "screenreaderOnly": "StringLiteral",
          "order": "StringLiteral",
          "name": "StringLiteral"
        },
        "hashContexts": {
          "screenreaderOnly": depth0,
          "order": depth0,
          "name": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 33,
            "column": 1
          },
          "end": {
            "line": 33,
            "column": 91
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-before"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 7,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 55
          }
        }
      })) + alias3((lookupProperty(helpers, "plugin-outlet") || depth0 && lookupProperty(depth0, "plugin-outlet") || alias2).call(alias1, {
        "name": "plugin-outlet",
        "hash": {
          "name": "topic-list-header-before"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 8,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 51
          }
        }
      })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "bulkSelectEnabled", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "showTopicsAndRepliesToggle": "showTopicsAndRepliesToggle",
          "showBulkToggle": "toggleInTitle",
          "newTopicsCount": "newTopicsCount",
          "newRepliesCount": "newRepliesCount",
          "newListSubset": "newListSubset",
          "experimentalTopicBulkActionsEnabled": "experimentalTopicBulkActionsEnabled",
          "canDoBulkActions": "canDoBulkActions",
          "canBulkSelect": "canBulkSelect",
          "bulkSelectHelper": "bulkSelectHelper",
          "bulkSelectEnabled": "bulkSelectEnabled",
          "order": "default",
          "name": "df_empty"
        },
        "hashTypes": {
          "showTopicsAndRepliesToggle": "PathExpression",
          "showBulkToggle": "PathExpression",
          "newTopicsCount": "PathExpression",
          "newRepliesCount": "PathExpression",
          "newListSubset": "PathExpression",
          "experimentalTopicBulkActionsEnabled": "PathExpression",
          "canDoBulkActions": "PathExpression",
          "canBulkSelect": "PathExpression",
          "bulkSelectHelper": "PathExpression",
          "bulkSelectEnabled": "PathExpression",
          "order": "StringLiteral",
          "name": "StringLiteral"
        },
        "hashContexts": {
          "showTopicsAndRepliesToggle": depth0,
          "showBulkToggle": depth0,
          "newTopicsCount": depth0,
          "newRepliesCount": depth0,
          "newListSubset": depth0,
          "experimentalTopicBulkActionsEnabled": depth0,
          "canDoBulkActions": depth0,
          "canBulkSelect": depth0,
          "bulkSelectHelper": depth0,
          "bulkSelectEnabled": depth0,
          "order": depth0,
          "name": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 16,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-after-main-link"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 62
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "plugin-outlet") || depth0 && lookupProperty(depth0, "plugin-outlet") || alias2).call(alias1, {
        "name": "plugin-outlet",
        "hash": {
          "name": "topic-list-header-after-main-link"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 0
          },
          "end": {
            "line": 31,
            "column": 58
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "showPosters", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(4, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 32,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "sortable": "sortable",
          "order": "posts",
          "number": "true",
          "name": "replies"
        },
        "hashTypes": {
          "sortable": "PathExpression",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "name": "StringLiteral"
        },
        "hashContexts": {
          "sortable": depth0,
          "order": depth0,
          "number": depth0,
          "name": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 35,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "sortable": "sortable",
          "order": "views",
          "number": "true",
          "name": "views"
        },
        "hashTypes": {
          "sortable": "PathExpression",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "name": "StringLiteral"
        },
        "hashContexts": {
          "sortable": depth0,
          "order": depth0,
          "number": depth0,
          "name": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 41,
            "column": 0
          },
          "end": {
            "line": 46,
            "column": 2
          }
        }
      })) + "\n" + alias3((lookupProperty(helpers, "raw") || depth0 && lookupProperty(depth0, "raw") || alias2).call(alias1, "topic-list-header-column", {
        "name": "raw",
        "hash": {
          "sortable": "sortable",
          "order": "activity",
          "number": "true",
          "name": "activity"
        },
        "hashTypes": {
          "sortable": "PathExpression",
          "order": "StringLiteral",
          "number": "StringLiteral",
          "name": "StringLiteral"
        },
        "hashContexts": {
          "sortable": depth0,
          "order": depth0,
          "number": depth0,
          "name": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 47,
            "column": 0
          },
          "end": {
            "line": 52,
            "column": 2
          }
        }
      })) + alias3((lookupProperty(helpers, "raw-plugin-outlet") || depth0 && lookupProperty(depth0, "raw-plugin-outlet") || alias2).call(alias1, {
        "name": "raw-plugin-outlet",
        "hash": {
          "name": "topic-list-header-after"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 53,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 54
          }
        }
      })) + alias3((lookupProperty(helpers, "plugin-outlet") || depth0 && lookupProperty(depth0, "plugin-outlet") || alias2).call(alias1, {
        "name": "plugin-outlet",
        "hash": {
          "name": "topic-list-header-after"
        },
        "hashTypes": {
          "name": "StringLiteral"
        },
        "hashContexts": {
          "name": depth0
        },
        "types": [],
        "contexts": [],
        "data": data,
        "loc": {
          "start": {
            "line": 54,
            "column": 0
          },
          "end": {
            "line": 54,
            "column": 50
          }
        }
      }));
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/topic-list-header", template, {
    core: true
  });
  var _default = _exports.default = template;
});